import React, { useEffect, useReducer, createContext } from "react"

import { format, closestIndexTo } from "date-fns"
import EuropeanTourStroke from "./euro-tour-stroke"

import PageLoading from "../layout/page-loading"

const TournamentContext = createContext("tournament")

const initialState = {
  tournamentFormat: "stroke",
  tournamentName: "",
  tournamentCourse: "",
  tournamentHoles: [],
  tournamentLocation: "",
  tournamentId: null,
  tournamentStartDate: "",
  tournamentEndDate: "",
}

function reducer(state, action) {
  switch (action.type) {
    case "setTournament":
      return {
        ...state,
        tournamentFormat: action.payload.tournamentFormat,
        tournamentName: action.payload.tournamentName,
        tournamentCourse: action.payload.tournamentCourse,
        tournamentLocation: action.payload.tournamentLocation,
        tournamentId: action.payload.tournamentId,
        tournamentStartDate: action.payload.tournamentStartDate,
        tournamentEndDate: action.payload.tournamentEndDate,
      }
    case "setTournamentHoles":
      return {
        ...state,
        tournamentHoles: action.payload.tournamentHoles,
      }
    default:
      return state
  }
}

const getTournamentHoles = async (tid) => {
  const query = process.env.GATSBY_API_LOCAL
    ? `https://cors-anywhere.herokuapp.com/https://www.europeantour.com/api/sportdata/HoleAverages/Event/${tid}/Round/1`
    : `/api/euro/holes/${tid}`

  const response = await fetch(query)
  const data = await response.json()

  return data.Courses[0].Holes.map((Hole) => ({
    hole: Hole.HoleNo,
    par: Hole.HolePar,
  }))
}

const getActiveTournament = async () => {
  const query = process.env.GATSBY_API_LOCAL
    ? "https://cors-anywhere.herokuapp.com/https://www.europeantour.com/api/sportdata/MiniSchedule/Tour/1"
    : "/api/euro/schedule"

  const response = await fetch(query)
  const data = await response.json()

  const events = data.Tours[0].Events

  const event =
    events[
      closestIndexTo(
        new Date(),
        events.map((tourn) => new Date(tourn.EndDate))
      )
    ]

  const tournamentFormat = "stroke"
  const tournamentName = event.EventName
  const tournamentCourse = event.Courses[0].Name
  const tournamentLocation = event.Courses[0].Location
  const tournamentId = event.EventId.toString()
  const tournamentStartDate = format(new Date(event.StartDate), "yyyy-mm-dd")
  const tournamentEndDate = format(new Date(event.EndDate), "yyyy-mm-dd")

  return {
    tournamentFormat,
    tournamentName,
    tournamentCourse,
    tournamentLocation,
    tournamentId,
    tournamentStartDate,
    tournamentEndDate,
  }
}

const EuropeanTour = () => {
  const todaysDate = format(new Date(), "MM/dd/yyyy")

  const [state, dispatch] = useReducer(reducer, initialState)

  const reloadApp = () => {
    getActiveTournament().then((tournament) => {
      dispatch({
        type: "setTournament",
        payload: tournament,
      })
      getTournamentHoles(tournament.tournamentId).then((holes) => {
        dispatch({
          type: "setTournamentHoles",
          payload: { tournamentHoles: holes },
        })
      })
    })
  }

  useEffect(() => {
    getActiveTournament().then((tournament) => {
      dispatch({
        type: "setTournament",
        payload: tournament,
      })
      getTournamentHoles(tournament.tournamentId).then((holes) => {
        dispatch({
          type: "setTournamentHoles",
          payload: { tournamentHoles: holes },
        })
      })
    })
  }, [])

  if (state.tournamentId === null) {
    return <PageLoading />
  }

  return (
    <TournamentContext.Provider
      value={{
        reload: () => reloadApp(),
      }}
    >
      <EuropeanTourStroke
        name={state.tournamentName}
        course={state.tournamentCourse}
        holes={state.tournamentHoles}
        location={state.tournamentLocation}
        tid={state.tournamentId}
        today={todaysDate}
        start={state.tournamentStartDate}
        end={state.tournamentEndDate}
      />
    </TournamentContext.Provider>
  )
}

export { EuropeanTour, TournamentContext }
