import React from "react"

import Layout from "../components/layout/layout"
import Header from "../components/layout/header"
import Footer from "../components/layout/footer"

import { RootProvider } from "../components/root-provider"
import SEO from "../components/seo"
import IOsBanner from "../components/iosbanner/iosbanner"
import { EuropeanTour } from "../components/euro-tour/euro-tour"

const Page = () => (
  <RootProvider>
    <div className="page-wrap">
      <SEO
        title="Fast Golf Scores | European Tour Leaderboard"
        description="Tour scores delivered in milliseconds"
        euro={true}
      />
      <Header />
      <Layout>
        <EuropeanTour />
      </Layout>
      <IOsBanner />
      <Footer />
    </div>
  </RootProvider>
)

export default Page
