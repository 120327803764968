import PropTypes from "prop-types"
import React, { useReducer, useEffect, useContext, useState } from "react"

import { format, isAfter, getMonth } from "date-fns"

import LeaderboardStroke from "../leaderboard-stroke/leaderboard-stroke"

import { TournamentContext } from "./euro-tour"
import { RootContext } from "../root-provider"

import PageLoading from "../layout/page-loading"

import TournamentHeader from "../tournament/tournament-header"

import { addFavorite, removeFavorite, getFavorites } from "../../util/favorites"

import { useInterval } from "../../util/useInterval"

const initialState = {
  started: false,
  players: [],
  favorites: [],
  roundState: "",
  currentRound: 0,
  percentComplete: 0,
  favoritesEnabled: true,
  loading: true,
  error: false,
}

const formatScore = (score) => {
  if (score === null) {
    return "--"
  } else if (score > 0) {
    return `+${score}`
  } else if (score === 0) {
    return "E"
  } else {
    return score.toString()
  }
}

const formatPosition = (position, missedCut) => {
  if (!position) {
    return "--"
  }
  if (position === "DISQ") {
    return "DQ"
  }
  if (position === "W/D") {
    return "WD"
  }
  if (position === "RETD") {
    return "WD"
  }
  if (missedCut) {
    return "CUT"
  }
  if (position.includes("T")) {
    return `T${position.replace("T", "")}`
  }

  return position
}

const formatThru = (thru) => {
  if (!thru) {
    return "--"
  }
  return thru
}

const formatPlayerData = (scoringData, teeTimes) => {
  return {
    player_id: scoringData.PlayerId.toString(),
    current_position: formatPosition(
      scoringData.PositionDesc,
      scoringData.MissedCut
    ),
    status: scoringData.MissedCut ? "MC" : "active",
    total: formatScore(scoringData.ScoreToPar),
    totalRaw: scoringData.ScoreToPar,
    totalUnderPar: scoringData.ScoreToPar < 0,
    today: formatScore(scoringData.RoundScoreToPar),
    todayUnderPar: scoringData.RoundScoreToPar < 0,
    thru: formatThru(scoringData.HolesPlayedDesc),
    startHole: scoringData.FirstTee ? "1" : "10",
    teeTime: scoringData.TeeTime || null,
    showTeeTime:
      (teeTimes && !scoringData.MissedCut) ||
      (scoringData.RoundScoreToPar === null && !scoringData.MissedCut),
    isActive:
      !scoringData.MissedCut &&
      !RegExp("W/D|DISQ").test(scoringData.PositionDesc),
    player_bio: {
      first_name: scoringData.FirstName,
      short_name: scoringData.FirstName.substring(0, 1).toUpperCase(),
      last_name: scoringData.LastName,
    },
    scorecard: "fetch",
  }
}

const fetchLeaderboardData = async (tournamentId) => {
  const eventQuery = `${process.env.GATSBY_EURO_API}api/euro/event/${tournamentId}`
  const leaderboardQuery = `${process.env.GATSBY_EURO_API}api/euro/leaderboard/${tournamentId}`

  // eslint-disable-next-line
  const [leaderboardResponse, eventResponse] = await Promise.all([
    fetch(leaderboardQuery),
    fetch(eventQuery),
  ])

  const eventData = await eventResponse.json()

  const roundNumber = eventData.RoundNo
  const roundStatus = eventData.RoundStatus
  const teeTimes = eventData.RoundStatus === 1 && roundNumber === 1

  const leaderboardData = await leaderboardResponse.json()

  let players = leaderboardData.Players.map((player) => {
    return formatPlayerData(player, teeTimes)
  })

  const showprojectedcut =
    (roundNumber === 2 || (roundNumber === 3 && roundStatus === 1)) &&
    leaderboardData.CutValue !== null

  const activePlayers = players.filter((p) => p.status === "active")

  const totalHoles = activePlayers.length * 18

  const totalCompleted = activePlayers
    .map((p) => {
      return { ...p, thru: p.thru.replace("*", "").replace("F", "18") }
    })
    .filter((p) => p.thru !== "--")
    .reduce((total, player) => {
      return total + parseInt(player.thru, 10)
    }, 0)

  if (showprojectedcut) {
    const cutPosition = leaderboardData.CutValue

    const aboveCutLine = (player) => player.Position > cutPosition

    const cutIndex = leaderboardData.Players.findIndex(aboveCutLine)

    players = [
      ...players.slice(0, cutIndex),
      { player_id: "cutLine", score: "" },
      ...players.slice(cutIndex),
    ]
  }

  return {
    started: !teeTimes,
    players: players,
    currentRound: roundNumber,
    roundState: teeTimes ? "Groupings Official" : "", // formatRoundState(data.roundState),
    percentComplete: (totalCompleted / totalHoles) * 100,
  }
}

const fetchPlayerScorecard = async (
  tournamentId,
  playerId,
  currentRound,
  holes
) => {
  const query = process.env.GATSBY_API_LOCAL
    ? `https://cors-anywhere.herokuapp.com/https://www.europeantour.com/api/sportdata/Scorecard/Strokeplay/Event/${tournamentId}/Player/${playerId}`
    : `/api/euro/scorecard/${tournamentId}/player/${playerId}`

  const response = await fetch(query)
  const data = await response.json()

  const round = data.Rounds.find((r) => r.RoundNo === currentRound)

  const scores = round
    ? round.Holes.map((hole) => ({
        hole: hole.HoleNo,
        score: hole.Strokes,
        par: holes.find((h) => h.hole === hole.HoleNo).par,
      }))
    : null

  return {
    playerId,
    scorecard: {
      scores,
      in: round.StrokesIn,
      out: round.StrokesOut,
    },
  }
}

function reducer(state, action) {
  switch (action.type) {
    case "updateLeaderboard":
      return {
        ...state,
        started: action.payload.started,
        currentRound: action.payload.currentRound,
        players: action.payload.players,
        loading: false,
        roundState: action.payload.roundState,
        percentComplete: action.payload.percentComplete,
        error: false,
      }
    case "updatePlayer": {
      return {
        ...state,
        players: state.players.map((p) =>
          p.player_id === action.payload.playerId
            ? { ...p, scorecard: action.payload.scorecard }
            : p
        ),
      }
    }
    case "showLoader":
      return { ...state, loading: true }
    case "getFavorites":
      return { ...state, favorites: action.payload }
    case "addFavorite":
      return { ...state, favorites: state.favorites.concat(action.payload) }
    case "removeFavorite":
      return {
        ...state,
        favorites: state.favorites.filter((f) => f.key !== action.payload),
      }
    case "updateFavorite":
      return {
        ...state,
        favorites: state.favorites.map((f) =>
          f.key === action.payload.key ? { ...f, ...action.payload } : f
        ),
      }
    case "disableFavorites":
      return {
        ...state,
        favoritesEnabled: false,
      }
    case "updateError":
      return {
        ...state,
        loading: false,
        error: true,
      }
    default:
      return state
  }
}

const EuropeanTourStroke = ({
  name,
  course,
  holes,
  location,
  tid,
  today,
  start,
  end,
}) => {
  const tournamentContext = useContext(TournamentContext)
  const rootContext = useContext(RootContext)

  const [state, dispatch] = useReducer(reducer, initialState)

  const [isRefreshRunning, setRefreshRunning] = useState(true)

  const handleFavoriteChange = (playerId, firstName, lastName, operation) => {
    if (operation === "add") {
      addFavorite(playerId, firstName, lastName, "euro", dispatch)
    }
    if (operation === "delete") {
      const player = state.favorites.find((p) => p.euro === playerId)
      if (player) {
        removeFavorite(player, dispatch)
      }
    }
  }

  const handleScorecardFetch = (playerId) => {
    fetchPlayerScorecard(tid, playerId, state.currentRound, holes).then(
      (data) => {
        dispatch({
          type: "updatePlayer",
          payload: data,
        })
      }
    )
  }

  const loadData = (tid) => {
    rootContext.updateLoading(true)
    dispatch({ type: "showLoader" })
    fetchLeaderboardData(tid)
      .then((data) => {
        dispatch({
          type: "updateLeaderboard",
          payload: data,
        })
        rootContext.updateLoading(false)
      })
      .catch(() => {
        dispatch({
          type: "updateError",
        })
        rootContext.updateLoading(false)
      })
  }

  useEffect(() => {
    if (window.indexedDB) {
      getFavorites().then((val) => {
        dispatch({
          type: "getFavorites",
          payload: val || [],
        })
      })
    } else {
      dispatch({
        type: "disableFavorites",
      })
    }
  }, [])

  useEffect(() => {
    loadData(tid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tid])

  useInterval(
    () => {
      loadData(tid)
    },
    isRefreshRunning ? 60000 : null
  )

  useEffect(() => {
    const checkVisibilityState = () => {
      if (document.visibilityState === "visible") {
        if (today !== format(new Date(), "MM/dd/yyyy")) {
          tournamentContext.reload()
          if (window.ga) {
            window.ga("send", "event", "App view", "different day")
          }
        } else {
          loadData(tid)
          setRefreshRunning(true)
          if (window.ga) {
            window.ga("send", "event", "App view", "same day")
          }
        }
      } else {
        setRefreshRunning(false)
      }
    }
    document.addEventListener("visibilitychange", checkVisibilityState)
    return () => {
      document.removeEventListener("visibilitychange", checkVisibilityState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tid, today, tournamentContext])

  if (state.loading && state.players.length === 0) {
    return <PageLoading />
  }
  if (
    state.error ||
    (state.players &&
      state.players.length > 0 &&
      state.players[0].teeTime === null &&
      state.players[0].totalRaw === null)
  ) {
    return (
      <div className="page-inner-padding">
        <TournamentHeader name={name} course={course} location={location} />

        {start !== "" && isAfter(new Date(start), new Date()) ? (
          <div>
            <p>
              {format(new Date(start), "MMMM d")}
              {getMonth(new Date(start)) === getMonth(new Date(end))
                ? `-${format(new Date(end), "d")}`
                : ` - ${format(new Date(end), "MMMM  d")}`}
            </p>
            <p>
              <span>-- tournament not started yet --</span>
            </p>
          </div>
        ) : (
          <p>-- scoring data not available --</p>
        )}
      </div>
    )
  }

  const euroFavoriteIds = state.favorites
    .filter((p) => p.euro)
    .map((p) => p.euro)

  return (
    <>
      <header className="page-inner-padding">
        <TournamentHeader name={name} course={course} location={location} />
      </header>

      {state.players.length > 0 && (
        <section>
          <LeaderboardStroke
            players={state.players}
            started={state.started}
            status={state.roundState}
            favorites={euroFavoriteIds}
            favoritesEnabled={state.favoritesEnabled}
            onFavoriteChange={handleFavoriteChange}
            onLoadPlayerScore={handleScorecardFetch}
          />
        </section>
      )}
    </>
  )
}

EuropeanTourStroke.propTypes = {
  name: PropTypes.string,
  location: PropTypes.string,
  course: PropTypes.string,
  holes: PropTypes.array,
  tid: PropTypes.string,
  today: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
}

EuropeanTourStroke.defaultProps = {
  name: "",
  course: "",
  holes: [],
  location: "",
  tid: "",
  today: "",
  start: "",
  end: "",
}

export default EuropeanTourStroke
